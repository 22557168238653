import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './slices/rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { NODE_ENV } from "../environment";

// Define middleware


const persistConfig = {
  key: 'root',
  storage,
};

// removing the redux persist store logic
// const persistedReducer = persistReducer(persistConfig, rootReducer);
const persistedReducer = rootReducer;

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware)=> getDefaultMiddleware({
    thunk: true, // Enable Redux Thunk middleware
    serializableCheck: false, // Disable serializable check as it's handled by RTK
  }),
  devTools:( NODE_ENV !== 'production' ),
});

// Initialize persistor
// const persistor = persistStore(store);

export { store, 
  // persistor 
};
