import { combineReducers } from '@reduxjs/toolkit';
import commonReducer from './commonSlice';
import questionnaireReducer from './questionnaireSlice';
import resultReducer from './resultSlice';

const rootReducer = combineReducers({
  common: commonReducer,
  questionnaire: questionnaireReducer,
  result: resultReducer,
  // Add more slice reducers here if you have additional slices
});

export default rootReducer;