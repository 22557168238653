import React, { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { APP_URL } from '../../utils/constants'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Cookies from 'js-cookie'
// import { setPage, setStateFromCookies } from '../../store/actions'
import {
  setPage,
  setStateFromCookies,
} from '../../store/slices/questionnaireSlice'

import Pages from './pages'
import { appendRoute, getButtonTitle, getCookieMeta } from '../../utils/helpers'

import CircularProgress from '../../components/progress'
import { TOTAL_PAGES } from '../../utils/constants'

const Questionnaire = ({ questionnaireLableData, modalLabelData }) => {
  const nodeRef = React.createRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { pages, isLoading } = useSelector(({ questionnaire }) => questionnaire)
  const setStep = useCallback(
    (page) => {
      dispatch(setPage({ page: page, setCookies: Cookies.set }))
    },
    [dispatch, Cookies.set],
  )

  const previousStep = (currentPageNo) => {
    let url = ''
    if (currentPageNo === 1) {
      url = '/'
    } else if (currentPageNo === 9) {
      if (
        ((pages[6].answerNo === 0 || pages[6].answerNo === 2) &&
          pages[7].answerNo === 0) ||
        (pages[6].answerNo === 1 && pages[7].answerNo === 0) ||
        (pages[6].answerNo === 3 &&
          (pages[7].answerNo === 1 || pages[7].answerNo === 2))
      ) {
        url = appendRoute(currentPageNo - 2, pages[6]?.answerNo)
      } else {
        url = appendRoute(currentPageNo - 1, pages[6]?.answerNo)
      }
    } else if (currentPageNo === 15) {
      url = appendRoute(currentPageNo - 2, pages[6]?.answerNo)
    } else if (currentPageNo === 20) {
      url =
        pages[1].answerNo === 0 || pages[1].answerNo === 1
          ? appendRoute(currentPageNo - 1, pages[6]?.answerNo)
          : appendRoute(currentPageNo - 2, pages[6]?.answerNo)
    } else if (currentPageNo === 25) {
      url =
        pages[22].answerNo === 0
          ? appendRoute(currentPageNo - 2, pages[6]?.answerNo)
          : appendRoute(currentPageNo - 3, pages[6]?.answerNo)
    } else if (currentPageNo === 31) {
      url =
        pages[28].answerNo === 0
          ? appendRoute(currentPageNo - 3, pages[6]?.answerNo)
          : appendRoute(currentPageNo - 1, pages[6]?.answerNo)
    } else {
      url = appendRoute(currentPageNo - 1, pages[6]?.answerNo)
    }
    navigate({
      pathname: url,
      state: { fromPage: currentPageNo },
    })
  }
  const currentPageNo = Number.parseInt(
    location.pathname.split('/')[2] || '1',
    10,
  )
  const classes =
    currentPageNo > Number(location.state?.fromPage)
      ? 'slide-forward'
      : 'slide-backward'

  useEffect(() => {
    const h2Elements = document.getElementsByTagName('h2')
    h2Elements[0]?.focus()
  }, [pages])

  /* setTimeout(()=>{
    const words = document.title.split('|')[0].replace(' ', '').toLowerCase();
    const url = parseInt(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));
    if(Number.isInteger(url)){
      const newPath = window.location.pathname + '/' +words;
      window.history.replaceState(null, "", newPath)
    }
  }, 350); */

  useEffect(() => {
    if (Cookies.get('localState')) {
      dispatch(setStateFromCookies(JSON.parse(Cookies.get('localState'))))
    }
  }, [dispatch, Cookies])
  const userId = Cookies.get('userId')
  if (!userId) {
    axios
      .post(`${APP_URL}/session`)
      .then(({ data: { id } }) => Cookies.set('userId', id, getCookieMeta()))
  }
  if (
    !Cookies.get('existingUser') === undefined ||
    !Cookies.get('existingUser')
  ) {
    if ((Cookies.get('userId'))) {
      Cookies.set('existingUser', true, getCookieMeta())
    } else {
      axios.post(`${APP_URL}/session`).then(({ data: { id } }) => {
        Cookies.set('userId', id, getCookieMeta())
        Cookies.set('existingUser', true, getCookieMeta())
      })
    }
  }
  useEffect(() => {
    if (currentPageNo == '25') {
      localStorage.setItem('firstTime26', 0)
      const firstTime = localStorage.setItem('firstTime26', 0)
    }
  }, [currentPageNo])

  useEffect(() => {
    // console.log("currentPageNo",currentPageNo)
    // console.log("cookies.localState",cookies.localState)
    // console.log("alain",cookies.userId)
    if (
      (Cookies.get('localState') == undefined ||
        Cookies.get('localState') == 'undefined') &&
      currentPageNo > 1
    ) {
      navigate({
        pathname: '/',
      })
    }
    //  else if (
    //   Cookies.get('userId') &&
    //   parseInt(Cookies.get('userId')) < 10 &&
    //   Cookies.get('localState')
    // ) {
    //   navigate({
    //     pathname: '/',
    //   })
    // }
     else {
      let notAnswered

      for (let i = 1; i <= currentPageNo - 1; i++) {
        // console.log('i',i)
        if (i != 14 && i != 24 && i != 8) {
          // console.log("currentPageNo2",currentPageNo)
          let getParseLocalhost = JSON.parse(Cookies.get('localState'))
          if (i === 23) {
            // console.log('23 : ', getParseLocalhost[23]['aantal_zonnepanelen'])
            if (
              getParseLocalhost[22]['answerNo'] != 1 &&
              (getParseLocalhost[i] == undefined ||
                getParseLocalhost[i]['aantal_zonnepanelen'] === undefined ||
                getParseLocalhost[i]['aantal_zonnepanelen'] <= 0)
            ) {
              // console.log(i+" not answered");
              notAnswered = i
              break
            }
          } else if (i === 26) {
            if (
              getParseLocalhost[i] &&
              (getParseLocalhost[i] == undefined ||
                getParseLocalhost[i]['aardgasverbruik'] === undefined ||
                getParseLocalhost[i]['aardgasverbruik'] < 0)
            ) {
              // console.log(i+" not answered");
              notAnswered = i
              break
            }
          } else if (i === 3) {
            if (
              getParseLocalhost[i] &&
              (getParseLocalhost[i] == undefined ||
                getParseLocalhost[i]['oppervlakte_input'] === undefined ||
                getParseLocalhost[i]['oppervlakte_input'] < 0)
            ) {
              if (
                getParseLocalhost[i]['answerNo'] == undefined ||
                getParseLocalhost[i]['answerNo'] < 0
              ) {
                // console.log(i+" not answered");
                notAnswered = i
                break
              }
            }
          } else if (i === 29 || i === 30) {
            if (
              getParseLocalhost[28]['answerNo'] > 0 &&
              (getParseLocalhost[i] == undefined ||
                getParseLocalhost[i]['answerNo'] === undefined ||
                getParseLocalhost[i]['answerNo'] < 0)
            ) {
              // console.log(i+" not answered");
              notAnswered = i
              break
            }
          } else if (i === 19) {
            if (
              (getParseLocalhost[1]['answerNo'] == 0 ||
                getParseLocalhost[1]['answerNo'] == 1) &&
              (getParseLocalhost[i] == undefined ||
                getParseLocalhost[i]['answerNo'] === undefined ||
                getParseLocalhost[i]['answerNo'] < 0)
            ) {
              // console.log(i+" not answered");
              notAnswered = i
              break
            }
          } else {
            if (
              currentPageNo > 1 &&
              (getParseLocalhost[i] == undefined ||
                getParseLocalhost[i]['answerNo'] == undefined ||
                getParseLocalhost[i]['answerNo'] < 0)
            ) {
              // console.log(i+" not answered");
              notAnswered = i
              break
            }
          }
        }
      }
      if (notAnswered > 0) {
        previousStep(notAnswered + 1)
      }
    }
  }, [currentPageNo])
  return (
    <>
      <Link className="skiplink" to={`${location.pathname}#main`}>
        Skip to content
      </Link>
      <div className="container">
        <div className="preview-container content-container">
          <div className="questionnaire-header">
            <button
              type="button"
              className="button button-secondary button-small back-button parent"
              onClick={() => previousStep(currentPageNo)}
            >
              <i
                className="icon-chevron-left"
                aria-hidden="true"
                title="Left"
              />
              {getButtonTitle(currentPageNo, pages)}
            </button>

            <span className="loader">
              <CircularProgress
                progress={Math.round((currentPageNo / TOTAL_PAGES) * 100)}
                size={39.3}
                strokeWidth={5.5}
              />
            </span>
          </div>
          <main
            id="main"
            className="questionnaire-main"
            data-loading={isLoading}
          >
            {isLoading && (
              <div className="spinner">
                <div>&hellip;loading</div>
              </div>
            )}
            {/* <AnimatedSwitch
              setStep={setStep}
              pages={pages}
              currentPage={currentPageNo}
              questionnaireLableData={questionnaireLableData}
              modalLabelData={modalLabelData}
            /> */}
            <TransitionGroup
              childFactory={(child) =>
                React.cloneElement(child, {
                  classNames: classes,
                })
              }
            >
              <CSSTransition
                nodeRef={nodeRef}
                key={location.key}
                classNames="slide"
                timeout={1000}
              >
                <Pages
                  pages={pages}
                  setStep={setStep}
                  questionnaireLableData={questionnaireLableData}
                  modalLabelData={modalLabelData}
                />
              </CSSTransition>
            </TransitionGroup>
          </main>
        </div>
      </div>
    </>
  )
}
export default Questionnaire
