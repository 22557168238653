import React, { useEffect, useRef, useState } from 'react'
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import WMTSLayer from '@arcgis/core/layers/WMTSLayer'
import Search from '@arcgis/core/widgets/Search'
import { locationToAddress } from '@arcgis/core/rest/locator'
import { setLocale } from '@arcgis/core/intl'
import axios from 'axios'
import { APP_URL } from '../../utils/constants'
import { sessionPutCall } from '../../utils/apiActions'
import Cookies from 'js-cookie'

import './heatMap.scss'

const urlBase =
  'https://extags.stad.gent/server/rest/services/EXT_CheckJeHuis/gevelisolatie/MapServer/4/'
const urlGebouwen =
  'https://extags.stad.gent/server/rest/services/EXT_CheckJeHuis/gevelisolatie/MapServer/3/'
const urlNumber =
  'https://extags.stad.gent/server/rest/services/EXT_CheckJeHuis/gevelisolatie/MapServer/2/'

const mapConfig = {
  center: [3.7252294866765903, 51.05451468609126],
  zoom: 14,
  maxZoom: 21,
  scale: 2500,
}

let searchWidget
let view

export default function VerwarmingHeatMap(props) {
  const { layerUrl } = props
  const mapRef = useRef()
  const [state, setState] = useState({
    searchTerm: '',
    options: [],
    optionsDropdown: false,
    searchValue: '',
  })

  const popupContent = `<p>{address}, {Postal} {city}</p>`
  const template = {
    outFields: ['address', "Postal", "city"],
    title: 'Adres',
    content: popupContent,
  }

  useEffect(() => {
    setLocale('nl')


    const baseLayer = new WMTSLayer({
      url: 'https://geo.gent.be/geoserver/gwc/service/wmts',
      copyright: 'Gent',
      activeLayer: {
        id: 'SG-E-Stadsplan:Stadsplan',
      },
      maxScale: 0,
    })
    const labelClass = {
      // autocasts as new LabelClass()
      symbol: {
        type: 'text', // autocasts as new TextSymbol()
        color: '#23333a',
        font: {
          // autocast as new Font()
          family: 'Fira Sans',
          size: 8,
        },
      },
      labelPlacement: 'above-center',
      labelExpressionInfo: {
        expression: '$feature.HNRLABEL',
      },
      maxScale: 0,
      minScale: 3000
    }

    const featureLayer = new FeatureLayer({
      url: urlBase,
      maxScale: 0,
      // popupTemplate: template,
      opacity: 0.6,
    })
    const layerGebouwen = new FeatureLayer({
      url: urlGebouwen,
      maxScale: 0,
    })
    const layerNumber = new FeatureLayer({
      url: urlNumber,
      labelingInfo: [labelClass],
      opacity: 0,
      minScale: 3000,
      maxScale: 0,
    })
    const map = new Map({
      layers: [baseLayer, featureLayer, layerNumber, layerGebouwen],
    })

    view = new MapView({
      container: mapRef.current,
      map,
      ...mapConfig,
    })

    searchWidget = new Search({
      sources: [
        {

          url:
            'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',

          countryCode: 'BE',
          singleLineFieldName: 'SingleLine',
          name: 'Custom Geocoding Service',
          localSearchOptions: {
            minScale: 300000,
            distance: 50000,
          },
          maxResults: 1,
          maxSuggestions: 6,
          suggestionsEnabled: false,
          popupTemplate: template,
          outFields: ['address', 'Postal', 'city'],
        },
      ],
      view,
      includeDefaultSources: false,
    })
    {
    }

    searchWidget.on('search-complete', function ({ searchTerm: value }) {
      setState((st) => ({ ...st, searchTerm: value }))
    })

    // Add the search widget to the top right corner of the view
    view.ui.add(searchWidget, {
      position: 'top-right',
    })

    view.popup.defaultPopupTemplateEnabled === true

    view.on('click', function (event) {
      const params = {
        location: event.mapPoint,
      }

      locationToAddress('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer', params)
        .then(function (response) {
          setState((st) => ({ ...st, searchTerm: response.address }))
        })
        .catch(function (error) {
          // If the promise fails and no result is found, show a generic message
          console.log(error)
        })
    })

    axios.get(`${APP_URL}/session/${Cookies.get("userId")}`).then(
      ({
        data: {
          data: { session },
        },
      }) => {
        const { address } = session[0]
        setState((st) => ({ ...st, searchTerm: address }))
      },
    )

    return () => {
      if (view) {
        // destroy the map view
        view.destroy()
      }
    }

  }, [])

  useEffect(() => {
    if (searchWidget) {
      searchWidget.search(state.searchTerm)
    }

    sessionPutCall((Cookies.get("userId")), {
      address: state.searchTerm,
    })
  }, [state.searchTerm])

  return (
    <div
      className="webmap"
      style={{ height: '55%', position: 'relative' }}
      ref={mapRef}
    >
      {/* <input
        type="search"
        required="true"
        id="header-search"
        name="header-search"
        autoComplete="off"
        value={state.searchValue}
        className="search inline map-search"
        onChange={handleInputChange}
        style={{
          position: 'absolute',
          right: 0,
          width: '250px',
          height: '40px',
          top: '12px',
          right: '10px',
        }}
      />
      <div
        className="suggestions"
        style={{
          backgroundColor: state.optionsDropdown ? 'white' : 'transparent',
        }}
      >
        {state.optionsDropdown &&
          state.options.map((title) => (
            <button
              type="button"
              key={title}
              onClick={() => {
                handleSuggestionClick(title)
              }}
            >
              {title}
            </button>
          ))}
      </div> */}
    </div>
  )
}
