import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import {
  getQuestionKey,
  getModalOptions,
  getModalSelectedOption,
  getIncreaseDecreaseLabel,
  isEmptyObj,
} from '../../utils/helpers'

import ModalImprovementDetail from '../modal/modalImprovementDetail'
import MapModal from '../modal/solarMapModal'
import AchterMapModal from '../modal/achterMapModal'
import VerwarmingMapModal from '../modal/verwarmingMapModal'

import './improvementCard.scss'
import {
  handleSubmitModal,
  handleRemoveChanges,
} from '../../utils/resultHelpers'

import { getCalculationOfPage6 } from '../../utils/apiActions'
import Cookies from 'js-cookie'
// import { APP_URL } from '../../utils/constants'

const ImprovementCard = (props) => {
  const {
    type,
    title,
    subtitle,
    status,
    image,
    buttonText,
    pageIndex,
    btnEditText,
    consumptions,
    modal,
    pages,
    buildingData,
    resultSetState,
    isAreaUpdated,
  } = props


  const [updatedCard, setStateUpdated] = useState()
  const [zonnDefaultArea, setZonnDefaultArea] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showModalMap, setShowModalMap] = useState(false)
  const [showAchterModalMap, setAchterModalMap] = useState(false)
  const [showVerwarmingModalMap, setVerwarmingModalMap] = useState(false)
  const dispatch = useDispatch()
  const [vergronenArea, setVArea] = useState([Number(Cookies.get('vergronenArea1'))?Number(Cookies.get('vergronenArea1')):0,Number(Cookies.get('vergronenArea2'))?Number(Cookies.get('vergronenArea2')):0,Number(Cookies.get('vergronenArea3'))?Number(Cookies.get('vergronenArea3')):0])
  const verwarmingPage = useMemo(
    () =>
      !isEmptyObj(Cookies.get('localImprovements'))
        ? getCalculationOfPage6(
            JSON.parse(Cookies.get('localImprovements')),
            JSON.parse(Cookies.get('localImprovements'))['7']?.fromImprovement,
          )
        : {},
    [JSON.parse(Cookies.get('localImprovements'))],
  )

  const key = useMemo(
    () => getQuestionKey(pageIndex, JSON.parse(Cookies.get('localImprovements'))[6]?.answerNo),
    [pageIndex, JSON.parse(Cookies.get('localImprovements'))],
  )

  // const dropDownOptions = useMemo(
  //   () =>
  //     updatedCard
  //       ? getModalOptions(title, cookies?.localState,cookies?.userId)
  //       : getModalOptions(title, cookies?.localImprovements,cookies?.userId),
  //   [
  //     updatedCard,
  //     title,
  //     cookies?.localState,
  //     cookies?.localImprovements,
  //   ],
  // )
  const toggleModal = useCallback(() => {
    setShowModal((st) => !st)
  }, [])

  const onSubmit = useCallback(
    (...params) => {
      setShowModal((st) => !st)
      handleSubmitModal(...params, {
        userId: (Cookies.get('userId')),
        localImprovements: JSON.parse(Cookies.get('localImprovements')),
        pageIndex,
        key,
        dispatch,
        setCookies: Cookies.set,
        localState: JSON.parse(Cookies.get('localState')),
      })
      setStateUpdated(true)
    },
    [
      key,
      pageIndex,
      dispatch,
      Cookies.set,
      Cookies.get('localImprovements'),
      (Cookies.get('userId')),
      updatedCard
    ],
  )

  const onReset = useCallback(() => {
    setShowModal((st) => !st)
    handleRemoveChanges({
      userId: Cookies.get('userId'),
      localImprovements: JSON.parse(Cookies.get('localImprovements')),
      localState: JSON.parse(Cookies.get('localState')),
      pageIndex,
      key,
      dispatch,
      setCookies: Cookies.set,
    })
    setStateUpdated(false)
  }, [
    (Cookies.get('userId')),
    JSON.parse(Cookies.get('localState')),
    JSON.parse(Cookies.get('localImprovements')),
    pageIndex,
    key,
    dispatch,
    Cookies.set,
  ])

  const toggleMapModal = useCallback(() => {
    setShowModalMap((st) => !st)
  }, [])

  const toggleAchterMapModal = useCallback(() => {
    setAchterModalMap((st) => !st)
  }, [])

  // const toggleVerwarmingMapModal = useCallback(() => {
  //   setVerwarmingModalMap((st) => !st)
  // }, [])

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowModal(false)
    }
  }, [])

  const selectedSolOpp = useCallback((zonnepanelenArea) => {
    setZonnDefaultArea(zonnepanelenArea !== undefined ? zonnepanelenArea : 0)
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <article className="improvement-card">
      <div className="card-header">
        <div className="card-header-content">
          <h2>{title}</h2>
        </div>
        <div className="card-img">{image}</div>
      </div>
      <div className="card-body">
        <div className="card-status">
          <span>
            <span className="dot" style={{ background: status?.color }} />
          </span>
          <span className="sub-title">{subtitle}</span>
        </div>
        <p>
          {updatedCard
            ? 'Deze verbetering werd toegevoegd aan je plan.'
            : status?.key === 'Green' || status?.key === 'midgreen'
            ? status?.value
            : `${status?.value} ${title?.toLowerCase()}`}
        </p>
      </div>
      <div className="card-footer">
        {updatedCard ? (
          <button
            onClick={toggleModal}
            type="button"
            className="button button-secondary button-block improvement-card-button"
            aria-controls="modal-improvement"
            aria-expanded="false"
          >
            {btnEditText}
          </button>
        ) : (
          <button
            onClick={toggleModal}
            type="button"
            className={
              status?.key?.toLowerCase() === 'green'
                ? 'button button-secondary button-block improvement-card-button'
                : 'button button-primary button-block improvement-card-button'
            }
            aria-controls="modal-improvement"
            aria-expanded="false"
          >
            {buttonText}
          </button>
        )}
      </div>
      {showModal && (
        <ModalImprovementDetail
          modal={modal}
          type={type}
          modalTitle={title}
          itemKey={key}
          status={status?.key?.toLowerCase()}
          // dropDownOptions={dropDownOptions}
          // selectedOption={getModalSelectedOption(
          //   title,
          //   cookies?.localImprovements,
          //   consumptions,
          //   cookies?.userId,
          //   0
          // )}
          increaseDecreaseLabel={getIncreaseDecreaseLabel(title,pageIndex)}
          show={showModal}
          handleHideModal={toggleModal}
          handleAchterModalMap={toggleAchterMapModal}
          // handleVerwarmingModalMap={toggleVerwarmingMapModal}
          handleShowModalMap={toggleMapModal}
          handleHideModalMap={toggleMapModal}
          handleSubmitModal={onSubmit}
          handleRemoveChanges={onReset}
          setUpdated={updatedCard}
          pageIndex={pageIndex}
          zonnDefaultArea={zonnDefaultArea}
          improvedSelection={verwarmingPage}
          pages={pages}
          buildingData={buildingData}
          resultSetState={resultSetState}
          isAreaUpdated={isAreaUpdated}
          vergronenArea={vergronenArea}
          setVArea={setVArea}
        />
      )}
      {showModalMap && (
        <MapModal
          show={showModalMap}
          handleHideModal={toggleMapModal}
          selectedSolOpp={selectedSolOpp}
        />
      )}
      {showAchterModalMap && (
        <AchterMapModal
          show={showAchterModalMap}
          handleHideModal={toggleAchterMapModal}
          selectedSolOpp={selectedSolOpp}
        />
      )}
      {/* {showVerwarmingModalMap && (
        <VerwarmingMapModal
          show={showVerwarmingModalMap}
          handleHideModal={toggleVerwarmingMapModal}
          selectedSolOpp={selectedSolOpp}
        />
      )} */}
    </article>
  )
}

export default ImprovementCard
