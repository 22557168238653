import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getCookieMeta } from '../../utils/helpers'

export const setStateFromCookies = createAsyncThunk(
  'questionnaire/setStateFromCookies',
  async (pages, thunkAPI) => {
   
    try {
      const state = thunkAPI.getState()
      const updatedState = { ...state.questionnaire, pages }
      return updatedState
    } catch (error) {
      throw new Error('Failed to update state from cookies: ' + error.message)
    }
  },
)

export const setPage = createAsyncThunk(
  'questionnaire/setPage',
  async ({page, setCookies, },thunkAPI) => {
    try {
      const {
        questionnaire: { pages },
      } = thunkAPI.getState()

      const updatedPages =
        page.pageNo == 22
          ? {
              ...pages,
              [page.pageNo]: {
                ...pages[page.pageNo],
                ...page.data,
              },
              23: { aantal_zonnepanelen: '' },
              26: {
                aardgasverbruik: 0,
                elektriciteitsverbruik: 0,
                zonnepanelen: 0,
                energieproductie: 0,
              },
            }
          : {
              ...pages,
              [page.pageNo]: {
                ...pages[page.pageNo],
                ...page.data,
              },
            }

            if( page.pageNo == 22){
              localStorage.setItem('firstTime26', 0)
            }

      if (page.pageNo == 26) {
        localStorage.setItem(
          'elektriciteitsverbruik',
          page.data.elektriciteitsverbruik,
        )
        localStorage.setItem('aardgasverbruik', page.data.aardgasverbruik)
        localStorage.setItem('zonnepanelen', page.data.zonnepanelen)
        localStorage.setItem('energieproductie', page.data.energieproductie)
      }

      thunkAPI.dispatch(setLoading(true))
      setCookies('localState', JSON.stringify(updatedPages), getCookieMeta())

      // Simulating async behavior with setTimeout
      if (page.pageNo != 25)
        setTimeout(() => {
          // Dispatch action to indicate loading is complete
          thunkAPI.dispatch(setLoading(false))
        }, 500)

      // Return the updated pages as a success payload
      return updatedPages
    } catch (error) {
      // Return error message as a failure payload
      throw new Error('Failed to set page: ' + error.message)
    }
  },
)

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    pages: {
      1: {},
      2: {},
      3: { oppervlakte_input: 0 },
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      23: { aantal_zonnepanelen: '' },
      26: {
        aardgasverbruik: 0,
        elektriciteitsverbruik: 0,
        zonnepanelen: 0,
        energieproductie: 0,
      },
    },
    isLoading: false,
  },
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    setDefaultState(state, action) {
      state.pages = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setStateFromCookies.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    })
    builder.addCase(setPage.fulfilled, (state, action) => {
      return {
        ...state,
        pages: action.payload,
      }
    })
  },
})

export const { setLoading,setDefaultState } = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
