import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { APP_URL } from '../../utils/constants';

import {
    parseAdvice,
    parseHomeJson,
    parseMeerInfoModal,
    parsePageNotFound,
    parseQuestionnaire,
    parserResult,
  } from '../../utils/helpers'
import jsCookie from 'js-cookie';

// Function to parse data and return an object
function getObject(arr = []) {
  return arr.reduce((prevVal, currentVal) => {
    return { ...prevVal, [currentVal.key]: currentVal.value };
  }, {});
}

// Async thunk for fetching data
export const setAppText = createAsyncThunk(
  'common/fetchAppData',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true)); // Dispatch loading action
    try {
      const responses = await axios.all([
        // axios.get(`${APP_URL}/page/1`),
        // axios.get(`${APP_URL}/page/2`),
        // axios.get(`${APP_URL}/page/3`),
        // axios.get(`${APP_URL}/page/4`),
        // axios.get(`${APP_URL}/page/5`),
        // axios.get(`${APP_URL}/page/6`),
        // axios.get(`${APP_URL}/page/7`),
        // axios.get(`${APP_URL}/page/8`),
        // axios.get(`${APP_URL}/page/10`),
        // axios.get(`${APP_URL}/page/11`),
        axios.get(`${APP_URL}/page/6755083f-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/67552995-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/67552c0a-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/67552d93-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/67553605-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/67553dcc-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/67553ff9-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/675548c5-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/67554d05-0884-11ef-af54-024264400007`),
        axios.get(`${APP_URL}/page/6755508d-0884-11ef-af54-024264400007`)
      ]);

      const dataArr = responses.map((response) =>
        getObject(response.data[0].pageContent)
      );

      // Dispatch the success action with data payload
      // jsCookie.set("localState",JSON.stringify({}))
      return {
        home: parseHomeJson(dataArr[0]),
        result: parserResult(dataArr[1]),
        questionnaire: parseQuestionnaire({
          ...dataArr[2],
          ...dataArr[3],
        }),
        advice: parseAdvice(dataArr[4]),
        not_found: parsePageNotFound(dataArr[5]),
        modal_meer_info: parseMeerInfoModal(dataArr[6]),
        footer: dataArr[7],
        pdf: dataArr[8],
        modal: dataArr[9],
      };
    } catch (error) {
      // Dispatch the failure action with error payload
      return thunkAPI.rejectWithValue(error.message);
    } finally {
      thunkAPI.dispatch(setIsLoading(false)); // Dispatch loading complete action
    }
  }
);

// Slice creation
const commonSlice = createSlice({
  name: 'common',
  initialState: {
    home: {},
    result: {},
    questionnaire: {},
    advice: {},
    not_found: {},
    modal_meer_info: {},
    pdf: {},
    modal: {},
    isLoading: true,
    footer:{}
  },
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAppText.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(setAppText.rejected, (state, action) => {
        state.isLoading = false
      });
  },
});

export const { setIsLoading } = commonSlice.actions;
export default commonSlice.reducer;
