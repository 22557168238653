import axios from 'axios'
import {
  step11Improvement,
  step12Improvement,
  step13Improvement,
  step15Improvement,
  step16Improvement,
  step17Improvement,
  step18Improvement,
  step20Improvement,
  step21Improvement,
  step22Improvement,
  step27Improvement,
  step29Improvement,
  step30Improvement,
  step31Improvement,
} from '../images/improvement'
// import {
//   sendConsumptions,
//   setBespaar,
//   setInvesteer,
//   setMinderEnergie,
//   setCO2,
//   setPremies,
//   setElectricity,
//   setGas,
//   setVerkoopElectricity,
// } from '../store/actions'
import {
  sendConsumptions,
  setBespaar,
  setInvesteer,
  setMinderEnergie,
  setCO2,
  setPremies,
  setElectricity,
  setGas,
  setVerkoopElectricity,
} from '../store/slices/resultSlice'
import { improvementPostCall, updateConsumptions } from './apiActions'
import { getCookieMeta, getQuestionKey } from './helpers'
import { APP_URL } from './constants'

export async function setCardsLog(arr, userId, cookiesPages, previousCookies) {
  let data = []
  const dataStatus = []
  await arr.map((d, index) => {
    const initText = `Cards Initialisation\r\n*********************
    Is this card displayed by default ? Yes 
    Card Basic data :
      - title: ${JSON.stringify(d.title)}
      - subtitle: ${JSON.stringify(d.subtitle)}
      - status: ${JSON.stringify(d.status)}
      - buttonText: ${JSON.stringify(d.buttonText)}
      - btnEditText: ${JSON.stringify(d.btnEditText)}
    \r\n\r\n`
    const card = `card${Number(index + 1)}`

    data.push({
      logs: initText,
      card_id: card,
      userId,
    })

    let statusLogs
    if (card === 'card1') {
      statusLogs = stPage12(cookiesPages, 1)
    }
    if (card === 'card2') {
      statusLogs = stPage13(cookiesPages, 1)
    }
    if (card === 'card3') {
      statusLogs = stPage15(cookiesPages, 1)
    }
    if (card === 'card4') {
      statusLogs = stPage16(cookiesPages, 1)
    }
    if (card === 'card5') {
      statusLogs = stPage17(cookiesPages, 1)
    }
    if (card === 'card6') {
      statusLogs = stPage18(cookiesPages, 1)
    }
    if (card === 'card7') {
      statusLogs = stPage20(cookiesPages, 1)
    }
    if (card === 'card8') {
      statusLogs = stPage7(cookiesPages, 1)
    }
    if (card === 'card9') {
      statusLogs = stPage11(cookiesPages, previousCookies, 1)
    }
    if (card === 'card10') {
      statusLogs = stPage21(cookiesPages, 1)
    }
    if (card === 'card11') {
      statusLogs = stPage22(cookiesPages, 1)
    }
    if (card === 'card12') {
      statusLogs = stPage27(cookiesPages, 1)
    }
    if (card === 'card13') {
      statusLogs = stPage31(cookiesPages, 1)
    }
    if (card === 'card14') {
      statusLogs = stPage29(cookiesPages, 1)
    }
    if (card === 'card15') {
      statusLogs = stPage30(cookiesPages, 1)
    }

    const statusText = `Card's status logic\r\n*********************
    => Status : ${JSON.stringify(d.status)}
    => Because : ${statusLogs}\r\n\r\n`

    dataStatus.push({
      logs: statusText,
      card_id: card,
      userId,
    })
  })

  axios.post(`${APP_URL}/logs/addCardsLogs`, { data })

  setTimeout(() => {
    data = dataStatus
    axios.post(`${APP_URL}/logs/addCardsLogs`, { data })
  }, 5000)
}
// setCardsUpdateLog("card1", userId,data,cookies?.localState,updatedData[12])

export async function setCardsUpdateLog(
  card,
  userId,
  cookiesPages,
  previousCookies,
  answer_id,
  answer,
) {
  let statusLogs
  let status
  const data = []
  if (card === 'card1') {
    ;(statusLogs = stPage12(cookiesPages, 1)),
      (status = stPage12(cookiesPages, 0))
  }
  if (card === 'card2') {
    ;(statusLogs = stPage13(cookiesPages, 1)),
      (status = stPage13(cookiesPages, 0))
  }
  if (card === 'card3') {
    ;(statusLogs = stPage15(cookiesPages, 1)),
      (status = stPage15(cookiesPages, 0))
  }
  if (card === 'card4') {
    ;(statusLogs = stPage16(cookiesPages, 1)),
      (status = stPage16(cookiesPages, 0))
  }
  if (card === 'card5') {
    ;(statusLogs = stPage17(cookiesPages, 1)),
      (status = stPage17(cookiesPages, 0))
  }
  if (card === 'card6') {
    ;(statusLogs = stPage18(cookiesPages, 1)),
      (status = stPage18(cookiesPages, 0))
  }
  if (card === 'card7') {
    ;(statusLogs = stPage20(cookiesPages, 1)),
      (status = stPage20(cookiesPages, 0))
  }
  if (card === 'card8') {
    ;(statusLogs = stPage7(cookiesPages, 1)),
      (status = stPage7(cookiesPages, 0))
  }
  if (card === 'card9') {
    ;(statusLogs = stPage11(cookiesPages, previousCookies, 1)),
      (status = stPage11(cookiesPages, previousCookies, 0))
  }
  if (card === 'card10') {
    ;(statusLogs = stPage21(cookiesPages, 1)),
      (status = stPage21(cookiesPages, 0))
  }
  if (card === 'card11') {
    ;(statusLogs = stPage22(cookiesPages, 1)),
      (status = stPage22(cookiesPages, 0))
  }
  if (card === 'card12') {
    ;(statusLogs = stPage27(cookiesPages, 1)),
      (status = stPage27(cookiesPages, 0))
  }
  if (card === 'card13') {
    ;(statusLogs = stPage31(cookiesPages, 1)),
      (status = stPage31(cookiesPages, 0))
  }
  if (card === 'card14') {
    ;(statusLogs = stPage29(cookiesPages, 1)),
      (status = stPage29(cookiesPages, 0))
  }
  if (card === 'card15') {
    ;(statusLogs = stPage30(cookiesPages, 1)),
      (status = stPage30(cookiesPages, 0))
  }

  const statusText = `Card's Update\r\n*********************
    => New answer : ${answer_id} - ${answer}
    => New status : ${status}
    => New status Because : ${statusLogs}\r\n\r\n`

  data.push({
    logs: statusText,
    card_id: card,
    userId,
  })

  axios.post(`${APP_URL}/logs/addCardsLogs`, { data })

  // setTimeout(() => {
  //   data = dataStatus
  //   axios.post(`${APP_URL}/logs/addCardsLogs`, { data })
  // }, 5000)
}
export async function setCardsLogHide(logsSpecific, userId) {
  const data = []
  await logsSpecific.map((d, index) => {
    const initText = `Card's action\r\n*********************
    => Hide card 
    => Reason : ${JSON.stringify(d.reason)}\r\n\r\n`
    data.push({
      logs: initText,
      card_id: d.card_id,
      userId,
    })
  })
  setTimeout(() => {
    axios.post(`${APP_URL}/logs/addCardsLogs`, { data })
  }, 7000)
}

export const getBuildingType = (answer) => {
  switch (answer) {
    case 0:
      return 'open'
    case 1:
      return 'halfopen'
    case 2:
      return 'gesloten'
    default:
      return 'appartment'
  }
}

export const getArea = (answer, answer1, input, buildingData = {}) => {
  if (answer === 0 && input === 0) {
    return 'groot'
  }
  if (answer === 1 && input === 0) {
    return 'gemiddeld'
  }
  if (answer === 2 && input === 0) {
    return 'klein'
  }
  if (
    (answer1 === 0 && input >= buildingData.groot_open) ||
    (answer1 === 1 && input >= buildingData.groot_halfopen) ||
    (answer1 === 2 && input >= buildingData.groot_gesloten)
  ) {
    return 'groot'
  }
  if (
    (answer1 === 0 &&
      input < buildingData.groot_open &&
      input > buildingData.klein_open) ||
    (answer1 === 1 &&
      input < buildingData.groot_halfopen &&
      input > buildingData.klein_halfopen) ||
    (answer1 === 2 &&
      input < buildingData.groot_gesloten &&
      input > buildingData.klein_gesloten)
  ) {
    return 'gemiddeld'
  }
  if (
    (answer1 === 0 && input <= buildingData.klein_open) ||
    (answer1 === 1 && input <= buildingData.klein_halfopen) ||
    (answer1 === 2 && input <= buildingData.klein_gesloten)
  ) {
    return 'klein'
  }
  return 'groot'
}

export const getHeating = (answer, answerNext) => {
  switch (answer) {
    case 0:
      return 'Gas'
    case 1:
      return 'Elektriciteit'
    case 2:
      return 'Stookolie'
    default:
      switch (answerNext) {
        case 0:
          return 'Warmtenet'
        case 1:
          return 'Pellets'
        default:
          return 'Hout'
      }
  }
}

export const getHeatingWarmWater = (answer11, answer6, answer7) => {
  switch (answer11) {
    case 0:
    case 1:
      switch (answer6) {
        case 0:
          return 'gas'
        case 1:
          switch (answer7) {
            case 0:
            case 1:
              return 'elektrische boiler'
            default:
              return 'warmtepompboiler'
          }
        case 2:
          return 'Stookolie'
        default:
          return 'elektrische boiler'
      }
    case 2:
      return 'warmtepompboiler'
    case 3:
      return 'elektrische boiler'
    default:
      return 'gas'
  }
}

export const getVentilation = (answer) => {
  switch (answer) {
    case 0:
      return 'geen'
    case 1:
      return 'A'
    case 2:
      return 'C'
    case 3:
      return 'Cwtw'
    default:
      return 'Dwtw'
  }
}

export const getSlopingRoofIsolation = (answer) => {
  switch (answer) {
    case 0:
    case 4:
      return 'niet'
    case 1:
      return 'licht'
    case 2:
      return 'matig'
    default:
      return 'sterk'
  }
}
export const getFlatRoofIsolation = (answer, page12Value) => {
  switch (answer) {
    case 0:
      return 'niet'
    case 1:
      return 'licht'
    case 2:
      return 'matig'
    case 3:
      return 'sterk'
    default:
      return page12Value
  }
}

export const getTypeOfHeating = (answerSix, answerSeven) => {
  switch (answerSix) {
    case 0:
    case 2:
      switch (answerSeven) {
        case 0:
          return 'Kachel'
        case 1:
          return 'Niet-condenserende ketel'
        case 2:
          return 'Condenserende ketel'
        default:
          return 'Condenserende ketel'
      }
    case 1:
      switch (answerSeven) {
        case 0:
          return 'Elektrische weerstandsverwarming'
        case 1:
        case 3:
          return 'Elektrische warmtepomp lucht-water'
        case 2:
          return 'Elektrische warmtepomp lucht-lucht'
        default:
          return 'Elektrische warmtepomp bodem-water'
      }
    default:
      switch (answerSeven) {
        case 0:
          return null
        default:
          return 'Kachel'
      }
  }
}

export const getRoofType = (answer) => {
  switch (answer) {
    case 0:
    case 1:
    case 2:
    case 3:
      return 'gemengd'
    default:
      return 'Dak_hellend'
  }
}

export const getFlorType = (answer) => {
  switch (answer) {
    case 0:
    case 1:
      return 'volleGrond'
    default:
      return 'gemengd'
  }
}

export const getWindowType = (answer) => {
  switch (answer) {
    case 0:
      return 'enkel'
    case 1:
      return 'dubbel'
    case 2:
      return 'hr'
    default:
      return 'driedubbel'
  }
}

export const getIsolationGround = (answer) => {
  switch (answer) {
    case 0:
      return 'sterk'
    case 1:
      return 'niet'
    default:
      return 'matig'
  }
}

export const getIsolationBasement = (answer) => {
  switch (answer) {
    case 0:
      return 'sterk'
    case 1:
      return 'niet'
    default:
      return 'matig'
  }
}

export const getWallIsolation = (answer) => {
  switch (answer) {
    case 0:
      return 'matig'
    case 1:
      return 'nietZonderSpouw'
    default:
      return 'licht'
  }
}

export function stPage7(pages, log) {
  if (
    (pages[6]?.answerNo === 0 &&
      (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
    (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 0) ||
    (pages[6]?.answerNo === 2 &&
      (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
    (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 2)
  ) {
    if (log === 1) {
      return ` 
          (pages[6]?.answerNo === 0 &&
          (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
          (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 0) ||
          (pages[6]?.answerNo === 2 &&
          (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
          (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 2)`
    }
    return 'red'
  }
  if (
    (pages[6]?.answerNo === 0 &&
      (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
    (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 1) ||
    (pages[6]?.answerNo === 2 &&
      (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
    (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 1)
  ) {
    if (log === 1) {
      return ` 
          (pages[6]?.answerNo === 0 &&
          (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
          (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 1) ||
          (pages[6]?.answerNo === 2 &&
          (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
          (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 1)`
    }

    return 'orange'
  }
  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage11(pages, prevPages, log) {
  if (pages[11]?.answerNo === 3) {
    if (log === 1) {
      return 'pages[11]?.answerNo === 3'
    }

    return 'red'
  }
  if (pages[11]?.answerNo === 4) {
    if (log === 1) {
      return 'pages[11]?.answerNo === 4'
    }

    return 'orange'
  }
  if (pages[11]?.answerNo === 0 && prevPages[11]?.answerNo === 0) {
    if (
      (pages[6]?.answerNo === 0 &&
        (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
      (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 0) ||
      (pages[6]?.answerNo === 2 &&
        (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
      (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 2)
    ) {
      if (log === 1) {
        return `
      (pages[11]?.answerNo === 0 && prevPages[11]?.answerNo === 0) && (
      (pages[6]?.answerNo === 0 &&
      (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
      (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 0) ||
      (pages[6]?.answerNo === 2 &&
      (pages[7]?.answerNo === 0 || pages[7]?.answerNo === 1)) ||
      (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 2))
      `
      }

      return 'red'
    }
    if (
      (pages[6]?.answerNo === 0 &&
        (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
      (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 1) ||
      (pages[6]?.answerNo === 2 &&
        (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
      (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 1)
    ) {
      if (log === 1) {
        return `
      (pages[11]?.answerNo === 0 && prevPages[11]?.answerNo === 0) && (
      (pages[6]?.answerNo === 0 &&
      (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
      (pages[6]?.answerNo === 1 && pages[7]?.answerNo === 1) ||
      (pages[6]?.answerNo === 2 &&
      (pages[7]?.answerNo === 2 || pages[7]?.answerNo === 3)) ||
      (pages[6]?.answerNo === 3 && pages[7]?.answerNo === 1)
      `
      }
      return 'orange'
    }
  }
  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage12(pages, log) {
  if (
    // (pages[14]?.answerNo === 0 ||
    //   pages[14]?.answerNo === 1 ||
    //   pages[14]?.answerNo === 2) &&
    pages[12]?.answerNo === 0 ||
    pages[12]?.answerNo === 1
  ) {
    if (log === 1) {
      return `
    (
      (pages[14]?.answerNo === 0 ||
      pages[14]?.answerNo === 1 ||
      pages[14]?.answerNo === 2
    ) &&
      (pages[12]?.answerNo === 0 || pages[12]?.answerNo === 1)
    )`
    }

    return 'red'
  }
  if (
    // (pages[14]?.answerNo === 0 || pages[14]?.answerNo === 2) &&
    pages[12]?.answerNo === 2
  ) {
    if (log === 1) {
      return '((pages[14]?.answerNo === 0 || pages[14]?.answerNo === 2) && pages[12]?.answerNo === 2) '
    }

    return 'orange'
  }
  if (pages[12]?.answerNo === 2) {
    if (log === 1) {
      return 'pages[14]?.answerNo === 1 && pages[12]?.answerNo === 2'
    }

    return 'midgreen'
  }
  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage13(pages, log) {
  if (
    // (pages[14]?.answerNo === 0 ||
    //   pages[14]?.answerNo === 1 ||
    //   pages[14]?.answerNo === 2) &&
    pages[13]?.answerNo === 0 ||
    pages[13]?.answerNo === 1
  ) {
    if (log === 1) {
      return `
    (
    (
      pages[14]?.answerNo === 0 ||
      pages[14]?.answerNo === 1 ||
      pages[14]?.answerNo === 2
    ) &&
    (pages[13]?.answerNo === 0 || pages[13]?.answerNo === 1)
    )`
    }

    return 'red'
  }
  if (
    // (pages[14]?.answerNo === 1 || pages[14]?.answerNo === 2) &&
    pages[13]?.answerNo === 2
  ) {
    if (log === 1) {
      return `
    (
      (pages[14]?.answerNo === 1 || pages[14]?.answerNo === 2) 
    &&
      pages[13]?.answerNo === 2
    )`
    }

    return 'orange'
  }
  if (pages[13]?.answerNo === 2) {
    if (log === 1) {
      return 'pages[14]?.answerNo === 0 && pages[13]?.answerNo === 2'
    }

    return 'midgreen'
  }
  if (log === 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage15(pages, log) {
  if (
    ((pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      (pages[15]?.answerNo === 0 || pages[15]?.answerNo === 1)) ||
    ((pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
      pages[15]?.answerNo === 0)
  ) {
    if (log === 1) {
      return `
    (
      ((pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
        (pages[15]?.answerNo === 0 || pages[15]?.answerNo === 1)) ||
      ((pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
        pages[15]?.answerNo === 0)
    )
    `
    }

    return 'red'
  }
  if (
    (pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
    pages[15]?.answerNo === 1
  ) {
    if (log === 1) {
      return `
    (
      (pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
      pages[15]?.answerNo === 1
    )
    `
    }

    return 'orange'
  }
  if (
    (pages[9]?.answerNo === 0 ||
      pages[9]?.answerNo === 1 ||
      pages[9]?.answerNo === 2 ||
      pages[9]?.answerNo === 3) &&
    pages[15]?.answerNo === 2
  ) {
    if (log === 1) {
      return `
    (
      (pages[9]?.answerNo === 0 ||
        pages[9]?.answerNo === 1 ||
        pages[9]?.answerNo === 2 ||
        pages[9]?.answerNo === 3) &&
      pages[15]?.answerNo === 2
    )
    `
    }

    return 'midgreen'
  }
  if (log === 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage16(pages, log) {
  if (
    ((pages[10]?.answerNo === 0 || pages[10]?.answerNo === 1) &&
      (pages[16]?.answerNo === 0 || pages[16]?.answerNo === 1)) ||
    ((pages[10]?.answerNo === 2 || pages[10]?.answerNo === 3) &&
      pages[16]?.answerNo === 0)
  ) {
    if (log === 1) {
      return `
    (
      ((pages[10]?.answerNo === 0 || pages[10]?.answerNo === 1) &&
        (pages[16]?.answerNo === 0 || pages[16]?.answerNo === 1)) ||
      ((pages[10]?.answerNo === 2 || pages[10]?.answerNo === 3) &&
        pages[16]?.answerNo === 0)
    )
    `
    }

    return 'red'
  }
  if (
    (pages[10]?.answerNo === 2 || pages[10]?.answerNo === 3) &&
    pages[16]?.answerNo === 1
  ) {
    if (log === 1) {
      return `
    (
      (pages[10]?.answerNo === 2 || pages[10]?.answerNo === 3) &&
      pages[16]?.answerNo === 1
    )
    `
    }

    return 'orange'
  }
  if (
    (pages[10]?.answerNo === 0 ||
      pages[10]?.answerNo === 1 ||
      pages[10]?.answerNo === 2 ||
      pages[10]?.answerNo === 3) &&
    pages[16]?.answerNo === 2
  ) {
    if (log === 1) {
      return `
    (
      (pages[10]?.answerNo === 0 ||
        pages[10]?.answerNo === 1 ||
        pages[10]?.answerNo === 2 ||
        pages[10]?.answerNo === 3) &&
      pages[16]?.answerNo === 2
    )
    `
    }

    return 'midgreen'
  }
  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage17(pages, log) {
  if (
    (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
    pages[17]?.answerNo === 1
  ) {
    if (log === 1) {
      return `
    (
      (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      pages[17]?.answerNo === 1
    )
    `
    }

    return 'red'
  }
  if (
    (pages[9]?.answerNo === 0 && pages[17]?.answerNo === 2) ||
    ((pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
      pages[17]?.answerNo === 1)
  ) {
    if (log === 1) {
      return `
    (
      (pages[9]?.answerNo === 0 && pages[17]?.answerNo === 2) ||
      ((pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
        pages[17]?.answerNo === 1)
    )
    `
    }

    return 'orange'
  }
  if (
    (pages[9]?.answerNo === 1 ||
      pages[9]?.answerNo === 2 ||
      pages[9]?.answerNo === 3) &&
    pages[17]?.answerNo === 2
  ) {
    if (log === 1) {
      return `
    (
      (pages[9]?.answerNo === 1 ||
        pages[9]?.answerNo === 2 ||
        pages[9]?.answerNo === 3) &&
      pages[17]?.answerNo === 2
    )
    `
    }

    return 'midgreen'
  }
  if (log === 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage18(pages, log) {
  const colors = ['red', 'orange', 'midgreen', 'green']

  const getPriority = (pageNo) => {
    if (
      (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      pages[pageNo]?.answerNo === 1
    ) {
      return 0
    }
    if (
      (pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
      (pages[pageNo]?.answerNo === 1 || pages[pageNo]?.answerNo === 2)
    ) {
      return 1
    }
    if (
      (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      pages[pageNo]?.answerNo === 2
    ) {
      return 2
    }

    return 3
  }
  const page18Priority = getPriority(18)
  const page19Priority = getPriority(19)

  if (page18Priority < page19Priority) {
    if (log === 1) {
      return `
    Colors : ['red', 'orange', 'midgreen', 'green']
    Page priority : page18Priority < page19Priority
    Logic :
    if (
      (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      pages[pageNo]?.answerNo === 1
    ) {
      return 0
    }
    if (
      (pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
      (pages[pageNo]?.answerNo === 1 || pages[pageNo]?.answerNo === 2)
    ) {
      return 1
    }
    if (
      (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      pages[pageNo]?.answerNo === 2
    ) {
      return 2
    }

    return 3
    `
    }

    return { status: colors[page18Priority], pageNo: 18 }
  }

  if (page19Priority < page18Priority) {
    if (log === 1) {
      return `
    Colors : ['red', 'orange', 'midgreen', 'green']
    Page priority : page19Priority < page18Priority
    Logic :
    if (
      (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      pages[pageNo]?.answerNo === 1
    ) {
      return 0
    }
    if (
      (pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
      (pages[pageNo]?.answerNo === 1 || pages[pageNo]?.answerNo === 2)
    ) {
      return 1
    }
    if (
      (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
      pages[pageNo]?.answerNo === 2
    ) {
      return 2
    }

    return 3
    `
    }

    return { status: colors[page19Priority], pageNo: 19 }
  }

  if (log === 1) {
    return `
  Colors : ['red', 'orange', 'midgreen', 'green']
  Page priority : Default page18
  Logic :
  if (
    (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
    pages[pageNo]?.answerNo === 1
  ) {
    return 0
  }
  if (
    (pages[9]?.answerNo === 2 || pages[9]?.answerNo === 3) &&
    (pages[pageNo]?.answerNo === 1 || pages[pageNo]?.answerNo === 2)
  ) {
    return 1
  }
  if (
    (pages[9]?.answerNo === 0 || pages[9]?.answerNo === 1) &&
    pages[pageNo]?.answerNo === 2
  ) {
    return 2
  }

  return 3
  `
  }
  return { status: colors[page18Priority], pageNo: 18 }
}

export function stPage20(pages, log) {
  if (pages[20]?.answerNo === 1 || pages[20]?.answerNo === 2) {
    if (log === 1) {
      return 'pages[20]?.answerNo === 1 || pages[20]?.answerNo === 2'
    }
    return 'orange'
  }
  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage21(pages, log) {
  if (pages[21]?.answerNo === 0) {
    if (log === 1) {
      return 'pages[21]?.answerNo === 0'
    }

    return 'red'
  }
  if (pages[21]?.answerNo === 1 || pages[21]?.answerNo === 2) {
    if (log === 1) {
      return 'pages[21]?.answerNo === 1 || pages[21]?.answerNo === 2'
    }

    return 'orange'
  }
  if (pages[21]?.answerNo === 3) {
    if (log === 1) {
      return 'pages[21]?.answerNo === 3'
    }

    return 'midgreen'
  }
  if (log === 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage22(pages, log) {
  if (pages[22]?.answerNo === 1) {
    if (log === 1) {
      return 'ages[22]?.answerNo === 1'
    }

    return 'red'
  }
  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage27(pages, log) {
  if (pages[27]?.answerNo === 1) {
    if (log === 1) {
      return 'pages[27]?.answerNo === 1'
    }
    return 'red'
  }

  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage31(pages, log) {
  if (pages[31]?.answerNo === 0) {
    if (log === 1) {
      return 'pages[31]?.answerNo === 0'
    }

    return 'red'
  }
  if (log === 1) {
    return 'Default'
  }

  return 'green'
}

export function stPage29(pages, log) {
  if (pages[29]?.answerNo === 1) {
    if (log === 1) {
      return 'pages[29]?.answerNo === 1'
    }
    return 'red'
  }

  if (log === 1) {
    return 'Default'
  }
  return 'green'
}

export function stPage30(pages, log) {
  if (pages[30]?.answerNo === 0) {
    if (log === 1) {
      return 'pages[30]?.answerNo === 0'
    }
    return 'red'
  }
  if (log === 1) {
    return 'Default'
  }
  return 'green'
}
export const pdfData = (userId, setPdfDom) => {
  axios
    .post(`${APP_URL}/email/pdf`, { userId })
    .then((response) => {
      setPdfDom(response.data.data[0].pdfDom)
      return response.data.data[0].pdfDom
    })
    .catch((err) => {
      console.log('Get pdfData Error', err)
    })
}

export const improvmentCardsProps = (
  cookiesPages,
  previousCookies,
  improvementCardLabelData,
  userId,
  init,
) => {
  // console.log('cookiesPages',cookiesPages)
  // console.log('cookiesPages', stPage12(cookiesPages, 0))
  const arr = [
    {
      title: improvementCardLabelData.improvement_hoofddak_title,
      subtitle: cookiesPages?.[12]?.[getQuestionKey(12)],
      image: step12Improvement,
      status: stPage12(cookiesPages, 0),
      pageIndex: '12',
      buttonText: improvementCardLabelData.improvement_hoofddak_button_text,
      btnEditText:
        improvementCardLabelData.improvement_hoofddak_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_bijdak_title,
      subtitle: cookiesPages?.[13]?.[getQuestionKey(13)],
      image: step13Improvement,
      status: stPage13(cookiesPages, 0),
      pageIndex: '13',
      buttonText: improvementCardLabelData.improvement_bijdak_button_text,
      btnEditText: improvementCardLabelData.improvement_bijdak_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData['improvement_ramen-leefruimte_title'],
      subtitle: cookiesPages?.[15]?.[getQuestionKey(15)],
      image: step15Improvement,
      status: stPage15(cookiesPages, 0),
      pageIndex: '15',
      buttonText:
        improvementCardLabelData['improvement_ramen-leefruimte_button_text'],
      btnEditText:
        improvementCardLabelData[
          'improvement_ramen-leefruimte_button_edit_text'
        ],
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData['improvement_ramen-slaapkamers_title'],
      subtitle: cookiesPages?.[16]?.[getQuestionKey(16)],
      image: step16Improvement,
      status: stPage16(cookiesPages, 0),
      pageIndex: '16',
      buttonText:
        improvementCardLabelData['improvement_ramen-slaapkamers_button_text'],
      btnEditText:
        improvementCardLabelData[
          'improvement_ramen-slaapkamers_button_edit_text'
        ],
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_voorgevel_title,
      subtitle: cookiesPages?.[17]?.[getQuestionKey(17)],
      image: step17Improvement,
      status: stPage17(cookiesPages, 0),
      pageIndex: '17',
      buttonText: improvementCardLabelData.improvement_voorgevel_button_text,
      btnEditText:
        improvementCardLabelData.improvement_voorgevel_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData['improvement_achter-en-zijgevels_title'],
      subtitle:
        cookiesPages?.[stPage18(cookiesPages).pageNo]?.[
          getQuestionKey(stPage18(cookiesPages).pageNo)
        ],
      image: step18Improvement,
      status: stPage18(cookiesPages, 0).status,
      pageIndex: '18',
      buttonText:
        improvementCardLabelData['improvement_achter-en-zijgevels_button_text'],
      btnEditText:
        improvementCardLabelData[
          'improvement_achter-en-zijgevels_button_edit_text'
        ],
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_vloerisolatie_title,
      subtitle: cookiesPages?.[20]?.[getQuestionKey(20)],
      image: step20Improvement,
      status: stPage20(cookiesPages, 0),
      pageIndex: '20',
      buttonText:
        improvementCardLabelData.improvement_vloerisolatie_button_text,
      btnEditText:
        improvementCardLabelData.improvement_vloerisolatie_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_verwarming_title,
      subtitle:
        cookiesPages?.[7]?.[getQuestionKey(7, cookiesPages?.[6]?.answerNo)],
      image: step18Improvement,
      status: stPage7(cookiesPages, 0),
      pageIndex: '7',
      buttonText: improvementCardLabelData.improvement_verwarming_button_text,
      btnEditText:
        improvementCardLabelData.improvement_verwarming_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData['improvement_warm-water_title'],
      subtitle: cookiesPages?.[11]?.[getQuestionKey(11)],
      image: step11Improvement,
      status: stPage11(cookiesPages, previousCookies, init),
      pageIndex: '11',
      buttonText:
        improvementCardLabelData['improvement_warm-water_button_text'],
      btnEditText:
        improvementCardLabelData['improvement_warm-water_button_edit_text'],
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_ventilatie_title,
      subtitle: cookiesPages?.[21]?.[getQuestionKey(21)],
      image: step21Improvement,
      status: stPage21(cookiesPages, 0),
      pageIndex: '21',
      buttonText: improvementCardLabelData.improvement_ventilatie_button_text,
      btnEditText:
        improvementCardLabelData.improvement_ventilatie_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_zonnepanelen_title,
      subtitle: cookiesPages?.[22]?.[getQuestionKey(22)],
      image: step22Improvement,
      status: stPage22(cookiesPages, 0),
      pageIndex: '22',
      buttonText: improvementCardLabelData.improvement_zonnepanelen_button_text,
      btnEditText:
        improvementCardLabelData.improvement_zonnepanelen_button_edit_text,
      type: 'solar',
    },
    {
      title: improvementCardLabelData.improvement_groendak_title,
      subtitle: cookiesPages?.[27]?.[getQuestionKey(27)],
      image: step27Improvement,
      status: stPage27(cookiesPages, 0),
      pageIndex: '27',
      buttonText: improvementCardLabelData.improvement_groendak_button_text,
      btnEditText:
        improvementCardLabelData.improvement_groendak_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_zonnewering_title,
      subtitle:
        cookiesPages?.[31]?.[getQuestionKey(31)] ===
        'Ja, het wordt te warm in de zomer'
          ? 'Onvoldoende zonnewering'
          : 'Meer zonnewering',
      image: step31Improvement,
      status: stPage31(cookiesPages, 0),
      pageIndex: '31',
      buttonText: improvementCardLabelData.improvement_zonnewering_button_text,
      btnEditText:
        improvementCardLabelData.improvement_zonnewering_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_Regenput_title,
      subtitle: cookiesPages?.[29]?.[getQuestionKey(29)],
      image: step29Improvement,
      status: stPage29(cookiesPages, 0),
      pageIndex: '29',
      buttonText: improvementCardLabelData.improvement_Regenput_button_text,
      btnEditText:
        improvementCardLabelData.improvement_Regenput_button_edit_text,
      type: 'hybrid',
    },
    {
      title: improvementCardLabelData.improvement_Vergroenen_title,
      subtitle:
        cookiesPages?.[30]?.[getQuestionKey(30)] === 'Ja'
          ? 'Onvoldoende groen'
          : 'Nee',
      image: step30Improvement,
      status: stPage30(cookiesPages, 0),
      pageIndex: '30',
      buttonText: improvementCardLabelData.improvement_Vergroenen_button_text,
      btnEditText:
        improvementCardLabelData.improvement_Vergroenen_button_edit_text,
      type: 'hybrid',
    },
  ]

  if (init === 1) {
    setCardsLog(arr, userId, cookiesPages, previousCookies)
  }

  const logsSpecific = []
  if (cookiesPages?.[31]?.answerNo === 1) {
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].title === 'Zonnewering') {
        arr.splice(i, 1)
        if (init === 1) {
          logsSpecific.push({
            card_id: 'card13',
            reason: "Question 31's answer === 1",
            userId,
          })
        }
      }
    }
  }
  if (cookiesPages?.[13]?.answerNo === 4) {
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].title === 'Bijdak') {
        arr.splice(i, 1)
        if (init === 1) {
          logsSpecific.push({
            card_id: 'card2',
            reason: "Question 13's answer === 4",
            userId,
          })
        }
      }
    }
  }
  if (
    (cookiesPages?.[28]?.answerNo === 0)
  ) {
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].pageIndex == 30) {
        arr.splice(i, 1)
        if (init === 1) {
          logsSpecific.push({
            card_id: 'card15',
            reason: "Question 31's answer === 1 || question 28's answer === 0",
            userId,
          })
        }
      }
    }
  }

  // if (
  //   (cookiesPages?.[29]?.answerNo === 0 ||
  //     cookiesPages?.[28]?.answerNo === 0) &&
  //   cookiesPages?.[29]?.answerNo === previousCookies?.[29]?.answerNo
  // ) {
  //   for (let i = 0; i < arr.length; i += 1) {
  //     if (arr[i].title === 'Regenput') {
  //       arr.splice(i, 1)
  //       if (init === 1) {
  //         logsSpecific.push({
  //           card_id: 'card14',
  //           reason: "Question 29's answer === 0 || question 28's answer === 0",
  //           userId,
  //         })
  //       }
  //     }
  //   }
  // }

  // Send logs to backend
  if (init === 1) {
    setCardsLogHide(logsSpecific, userId)
  }

  return arr
}

export const handleSubmitModal = (
  values,
  answerKey,
  selectedText,
  calculations = {},
  inputValue,
  selectedOption,
  componentProps,
) => {
  const {
    userId,
    localImprovements,
    pageIndex,
    key,
    dispatch,
    setCookies,
    localState,
  } = componentProps

  console.log('===>', values,
  answerKey,
  selectedText,
  calculations,
  inputValue,
  selectedOption)
  
  const data = localImprovements
  const answerNo = Number(values[answerKey])
  const updatedData =
    pageIndex !== '18' && pageIndex !== '22'
      ? {
          ...data,
          [pageIndex]: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
        }
      : pageIndex == '22'
      ? {
          ...data,
          22: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
          23: {
            aantal_zonnepanelen: Number(values.oppervlakte),
            fromImprovement: true,
          },
        }
      : {
          ...data,
          18: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
          19: {
            answerNo,
            [key]: selectedText,
            fromImprovement: true,
          },
        }

  if (componentProps.pageIndex === '12') {
    setCardsUpdateLog(
      'card1',
      userId,
      updatedData,
      data,
      updatedData[12].answerNo,
      updatedData[12][key],
    )
  }
  if (componentProps.pageIndex === '13') {
    setCardsUpdateLog(
      'card2',
      userId,
      updatedData,
      data,
      updatedData[13].answerNo,
      updatedData[13][key],
    )
  }
  if (componentProps.pageIndex === '15') {
    setCardsUpdateLog(
      'card3',
      userId,
      updatedData,
      data,
      updatedData[15].answerNo,
      updatedData[15][key],
    )
  }
  if (componentProps.pageIndex === '16') {
    setCardsUpdateLog(
      'card4',
      userId,
      updatedData,
      data,
      updatedData[16].answerNo,
      updatedData[16][key],
    )
  }
  if (componentProps.pageIndex === '17') {
    setCardsUpdateLog(
      'card5',
      userId,
      updatedData,
      data,
      updatedData[17].answerNo,
      updatedData[17][key],
    )
  }
  if (componentProps.pageIndex === '18') {
    setCardsUpdateLog(
      'card6',
      userId,
      updatedData,
      data,
      updatedData[18].answerNo,
      updatedData[18][key],
    )
  }
  if (componentProps.pageIndex === '20') {
    setCardsUpdateLog(
      'card7',
      userId,
      updatedData,
      data,
      updatedData[20].answerNo,
      updatedData[20][key],
    )
  }
  if (componentProps.pageIndex === '7') {
    setCardsUpdateLog(
      'card8',
      userId,
      updatedData,
      data,
      updatedData[7].answerNo,
      updatedData[7][key],
    )
  }
  if (componentProps.pageIndex === '11') {
    setCardsUpdateLog(
      'card9',
      userId,
      updatedData,
      data,
      updatedData[11].answerNo,
      updatedData[11][key],
    )
  }
  if (componentProps.pageIndex === '21') {
    setCardsUpdateLog(
      'card10',
      userId,
      updatedData,
      data,
      updatedData[21].answerNo,
      updatedData[21][key],
    )
  }
  if (componentProps.pageIndex === '22') {
    setCardsUpdateLog(
      'card11',
      userId,
      updatedData,
      data,
      updatedData[22].answerNo,
      updatedData[22][key],
    )
  }
  if (componentProps.pageIndex === '27') {
    setCardsUpdateLog(
      'card12',
      userId,
      updatedData,
      data,
      updatedData[27].answerNo,
      updatedData[27][key],
    )
  }
  if (componentProps.pageIndex === '31') {
    setCardsUpdateLog(
      'card13',
      userId,
      updatedData,
      data,
      updatedData[31].answerNo,
      updatedData[31][key],
    )
  }
  if (componentProps.pageIndex === '29') {
    setCardsUpdateLog(
      'card14',
      userId,
      updatedData,
      data,
      updatedData[29].answerNo,
      updatedData[29][key],
    )
  }
  if (componentProps.pageIndex === '30') {
    setCardsUpdateLog(
      'card15',
      userId,
      updatedData,
      data,
      updatedData[30].answerNo,
      updatedData[30][key],
    )
  }
  const paramsResponse = JSON.parse(localStorage.getItem('parameters'))
  const parameters = paramsResponse?.data?.data?.[0] || []
  const gasParam =
    parameters.find((param) => param?.name === 'CO2/kWh voor gas')?.value || 0
  const electParam =
    parameters.find((param) => param?.name === 'CO2/kWh voor elektriciteit')
      ?.value || 0

  const co2 = Number(
    calculations.pagesEnergyGas * gasParam +
      calculations.pagesEnergyElec * electParam,
  ).toFixed(2)

  dispatch(
    setInvesteer({
      [key]: calculations.investeer < 0 ? 0 : calculations.investeer,
    }),
  )

  dispatch(setBespaar({ [key]: calculations.bespaar }))
  dispatch(
    setPremies({ [key]: calculations.premies < 0 ? 0 : calculations.premies }),
  )
  dispatch(setCO2({ [key]: co2 < 0 ? 0 : co2 }))
  dispatch(
    setMinderEnergie({
      [key]: calculations.minderEnergie < 0 ? 0 : calculations.minderEnergie,
    }),
  )
  dispatch(
    setElectricity({
      [key]: calculations.pagesEnergyElec,
    }),
  )
  dispatch(
    setGas({
      [key]: calculations.pagesEnergyGas < 0 ? 0 : calculations.pagesEnergyGas,
    }),
  )
  dispatch(
    setVerkoopElectricity({
      [key]: calculations.verkoopElec,
    }),
  )

  sendConsumptions(userId, localImprovements, updatedData)
  setCookies('localImprovements', JSON.stringify(updatedData), getCookieMeta())
  improvementPostCall(
    userId,
    key,
    `selected option is ${selectedText} & input value is ${inputValue}`,
  )
}

export const getImprovedPages = (
  values,
  answerKey,
  selectedText,
  componentProps,
) => {
  const { localImprovements, pageIndex, page6Answer } = componentProps
  const data = localImprovements
  const answerNo = Number(values[answerKey])

  return {
    ...data,
    [pageIndex]: {
      ...data[pageIndex],
      answerNo,
      [getQuestionKey(pageIndex, page6Answer)]: selectedText,
    },
  }
}

export const handleRemoveChanges = (componentProps) => {
  const {
    userId,
    localImprovements,
    localState,
    pageIndex,
    key,
    dispatch,
    setCookies,
  } = componentProps

  const data = localImprovements
  const reSetData =
    pageIndex !== '18'
      ? {
          ...data,
          [pageIndex]: {
            ...localState?.[pageIndex],
          },
        }
      : {
          ...data,
          18: {
            ...localState?.[18],
          },
          19: {
            ...localState?.[19],
          },
        }
  if (componentProps.pageIndex === '12') {
    setCardsUpdateLog(
      'card1',
      userId,
      reSetData,
      data,
      reSetData[12].answerNo,
      reSetData[12][key],
    )
  }
  if (componentProps.pageIndex === '13') {
    setCardsUpdateLog(
      'card2',
      userId,
      reSetData,
      data,
      reSetData[13].answerNo,
      reSetData[13][key],
    )
  }
  if (componentProps.pageIndex === '15') {
    setCardsUpdateLog(
      'card3',
      userId,
      reSetData,
      data,
      reSetData[15].answerNo,
      reSetData[15][key],
    )
  }
  if (componentProps.pageIndex === '16') {
    setCardsUpdateLog(
      'card4',
      userId,
      reSetData,
      data,
      reSetData[16].answerNo,
      reSetData[16][key],
    )
  }
  if (componentProps.pageIndex === '17') {
    setCardsUpdateLog(
      'card5',
      userId,
      reSetData,
      data,
      reSetData[17].answerNo,
      reSetData[17][key],
    )
  }
  if (componentProps.pageIndex === '18') {
    setCardsUpdateLog(
      'card6',
      userId,
      reSetData,
      data,
      reSetData[18].answerNo,
      reSetData[18][key],
    )
  }
  if (componentProps.pageIndex === '20') {
    setCardsUpdateLog(
      'card7',
      userId,
      reSetData,
      data,
      reSetData[20].answerNo,
      reSetData[20][key],
    )
  }
  if (componentProps.pageIndex === '7') {
    setCardsUpdateLog(
      'card8',
      userId,
      reSetData,
      data,
      reSetData[7].answerNo,
      reSetData[7][key],
    )
  }
  if (componentProps.pageIndex === '11') {
    setCardsUpdateLog(
      'card9',
      userId,
      reSetData,
      data,
      reSetData[11].answerNo,
      reSetData[11][key],
    )
  }
  if (componentProps.pageIndex === '21') {
    setCardsUpdateLog(
      'card10',
      userId,
      reSetData,
      data,
      reSetData[21].answerNo,
      reSetData[21][key],
    )
  }
  if (componentProps.pageIndex === '22') {
    setCardsUpdateLog(
      'card11',
      userId,
      reSetData,
      data,
      reSetData[22].answerNo,
      reSetData[22][key],
    )
  }
  if (componentProps.pageIndex === '27') {
    setCardsUpdateLog(
      'card12',
      userId,
      reSetData,
      data,
      reSetData[27].answerNo,
      reSetData[27][key],
    )
  }
  if (componentProps.pageIndex === '31') {
    setCardsUpdateLog(
      'card13',
      userId,
      reSetData,
      data,
      reSetData[31].answerNo,
      reSetData[31][key],
    )
  }
  if (componentProps.pageIndex === '29') {
    setCardsUpdateLog(
      'card14',
      userId,
      reSetData,
      data,
      reSetData[29].answerNo,
      reSetData[29][key],
    )
  }
  if (componentProps.pageIndex === '30') {
    setCardsUpdateLog(
      'card15',
      userId,
      reSetData,
      data,
      reSetData[30].answerNo,
      reSetData[30][key],
    )
  }

  dispatch(setInvesteer({ [key]: 0 }))
  dispatch(setBespaar({ [key]: 0 }))
  dispatch(setPremies({ [key]: 0 }))
  dispatch(setMinderEnergie({ [key]: 0 }))
  dispatch(setElectricity({ [key]: 0 }))
  dispatch(setGas({ [key]: 0 }))
  dispatch(setCO2({ [key]: 0 }))
  dispatch(setVerkoopElectricity({ [key]: 0 }))
  sendConsumptions(userId, localImprovements, reSetData)
  setCookies('localImprovements', JSON.stringify(reSetData), getCookieMeta())
}

export const getSelectedDropdownOption = (optionNo) => {
  if (
    optionNo == 6 ||
    optionNo == 7 ||
    optionNo == 8 ||
    optionNo == 9 ||
    optionNo == 5
  ) {
    return true
  }
  return false
}
