import React, { useEffect } from 'react'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Provider, useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import 'regenerator-runtime/runtime'
import Cookies from 'js-cookie'
// import { store } from './store'
import { store } from './store/newStore'
import Home from './containers/home'
import Questionnaire from './containers/questionnaire'
import Result from './containers/result'
import PdfDisplayer from './containers/pdfDisplayer'
import PageNotFound from './containers/pageNotFound'
import GetAdvice from './containers/getAdvice'
import { APP_URL } from './utils/constants'
import { getCookieMeta } from './utils/helpers'
// import { setAppText } from './store/actions'
import { setAppText } from './store/slices/commonSlice'
import { CookiesProvider } from './utils/cookiesProvider'
import { isEmptyObj } from './utils/helpers'
// function ProtectedRoute(props) {
//   if (!Cookies.get('existingUser')) {
//     return <Redirect to="/home" />
//   }
//   return <Route {...props} />
// }

function Routing() {
  const { isLoading, home, result, questionnaire, advice, not_found, modal_meer_info, pdf, modal, footer } = useSelector((state) => state.common)
  const userId = (Cookies.get('userId'))
  const dispatch = useDispatch()

  const ProtectedRoute = ({ children }) => {
    if (!Cookies.get('existingUser')) {
      return <Navigate to="/home" replace />
    }

    return children
  }

  useEffect(() => {
    dispatch(setAppText())
  }, [dispatch])

  useEffect(() => {
    if (!userId) {
      axios
        .post(`${APP_URL}/session`)
        .then(
          ({ data: { uuid } }) => Cookies.set('userId', uuid, getCookieMeta()),
          Cookies.set('existingUser', true, getCookieMeta()),
        )
    }
  }, [userId])

  return (
    <>
      {(isLoading 
      || isEmptyObj(home)
      || isEmptyObj(result)
      || isEmptyObj(questionnaire)
      || isEmptyObj(advice)
      || isEmptyObj(not_found)
      || isEmptyObj(modal_meer_info)
      || isEmptyObj(pdf)
      || isEmptyObj(modal)
      || isEmptyObj(footer))  ? (
        <div className="spinner">
          <div>&hellip;loading</div>
        </div>
      ) : (
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  homeLabelData={home}
                  footerLabelData={footer}
                />
              }
            />
            <Route
              path="home"
              element={
                <Home
                  homeLabelData={home}
                  footerLabelData={footer}
                />
              }
            />
            <Route
              path="vraag/:pageNo/:PageName"
              element={
                <Questionnaire
                  questionnaireLableData={questionnaire}
                  modalLabelData={modal_meer_info}
                />
              }
            />
            <Route
              path="adviesappartementen"
              element={
                <ProtectedRoute>
                  <GetAdvice
                    adviceLabelData={advice}
                    footerLabelData={footer}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="advies"
              element={
                <ProtectedRoute>
                  <Result
                    resultLabelData={result}
                    footerLabelData={footer}
                    pdfLabelData={pdf}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="pdf/:id"
              element={
                <ProtectedRoute>
                  <PdfDisplayer />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <PageNotFound
                  notFoundLabelData={not_found}
                  footerLabelData={footer}
                />
              }
            />
          </Routes>
        </Router>
      )}
    </>
  )
}

function App() {
  useEffect(() => {
    const path = window.location.pathname

    if (path.includes('/vraag') && !Cookies.get('userId')) {
      Cookies.remove('localState', getCookieMeta())
      Cookies.remove('localImprovements', getCookieMeta())
      localStorage.clear()
      location.replace('/')
    } else if (path.includes('/advies') && !Cookies.get('userId')) {
      location.replace('/')
    }
  }, [])

  return (
    <CookiesProvider>
      <Provider store={store}>
        <Routing />
      </Provider>
    </CookiesProvider>
  )
}

export default App
