import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCalculationPayload, sessionPutCall } from '../../utils/apiActions'
import { APP_URL } from '../../utils/constants'
import { getObjValuesSum, isEmptyObj } from '../../utils/helpers'
import {
    getArea,
    getBuildingType,
    getFlatRoofIsolation,
    getFlorType,
    getHeating,
    getHeatingWarmWater,
    getIsolationBasement,
    getIsolationGround,
    getRoofType,
    getSlopingRoofIsolation,
    getTypeOfHeating,
    getVentilation,
    getWallIsolation,
    getWindowType,
} from '../../utils/resultHelpers'

async function getLoan(value) {
    try {
        // const paramsResponse = await axios.get(`${APP_URL}/parameters`)
        const paramsResponse = JSON.parse(localStorage.getItem('parameters'))

        const parameters = paramsResponse?.data?.data?.[0] || []
        const effectiveAnualIntrest =
            parameters.find((param) => param?.name === 'Jaarlijkse rentevoet lening')
                ?.value || 0
        const jearsForLoan =
            parameters.find((param) => param?.name === 'Looptijd lening')?.value || 0

        // Naming convention according to ticket number #119
        if (effectiveAnualIntrest > 0) {
            const X = effectiveAnualIntrest / 100
            const Y = X / 12
            const Z = jearsForLoan * 12
            const A = 1 + Y
            const B = A ** -Z
            const C = 1 - B
            const D = Y / C
            return D * value
        } else {
            const Z = jearsForLoan * 12
            return value / Z
        }
    } catch {
        return 0
    }
}

export async function sendConsumptions(userId, pages, improvedPages) {
    if (!isEmptyObj(pages) && !isEmptyObj(improvedPages)) {
        const currentPayload = await getCalculationPayload(pages)
        const improvedPayload = await getCalculationPayload(improvedPages, true)
        const storedCurrentPayload = localStorage.getItem('currentPayload')
        const storeImprovedPayload = localStorage.getItem('improvedPayload')
        const storedResult = localStorage.getItem('result')

        const checkCurrentPayload =
            JSON.stringify(currentPayload) === storedCurrentPayload
        const checkImprovedPayload =
            JSON.stringify(improvedPayload) === storeImprovedPayload

        if (checkCurrentPayload && checkImprovedPayload) {
            const result = JSON.parse(storedResult)
            if (result) {
                const { current } = result.data
                const { newMeasures } = result.data
                const currentConsumption = current.energyUseGas + current.energyUseElec
                const futureConsumption =
                    newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec

                sessionPutCall(userId, {
                    current_consumption: currentConsumption,
                    future_consumption: futureConsumption,
                })
            }
        }
    }
}

export const setInvesteer = createAsyncThunk(
    'result/setInvesteer',
    async (investerKeys, { getState }) => {
       
        try {
            const {
                result: { investeer },
            } = getState()
            const investeerPayload = { ...investeer, ...investerKeys }
            const investeerVal = getObjValuesSum(investeerPayload)

            let investeerValLoan =
                investeerVal -
                (investeerPayload?.groendak ? investeerPayload?.groendak : 0) -
                (investeerPayload?.klimaat ? investeerPayload?.klimaat : 0) -
                (investeerPayload?.verharding ? investeerPayload?.verharding : 0) -
                (investeerPayload?.regenwaterput ? investeerPayload?.regenwaterput : 0)

            const maximumbedragEnergielening = localStorage.getItem(
                'maximumbedragEnergielening',
            )
            localStorage.setItem('investeerValLoan', investeerValLoan)

            let loan = investeerValLoan
            if (investeerValLoan > maximumbedragEnergielening) {
                loan = await getLoan(maximumbedragEnergielening)
            } else {
                loan = await getLoan(investeerValLoan)
            }

            const energyLoan = loan.toFixed(2)

            return { investeer: investeerPayload, energielening: energyLoan }
        } catch (error) {
            throw new Error('Failed to set investeer: ' + error.message)
        }
    },
)
export async function getConsumptions(
    pages,
    improvedPages,
    setState,
    userId,
    state,
) { }

function getAnswer6And7(page7) {
    if (page7.answerNo > 13) {
        return {
            page6: {
                verwarming: 'Een andere bron',
                answerNo: 3,
            },
            page7: {
                ...page7,
                answerNo: page7.answerNo - 14,
            },
        }
    }
    if (page7.answerNo > 9) {
        return {
            page6: {
                verwarming: 'Stookolie',
                answerNo: 2,
            },
            page7: {
                ...page7,
                answerNo: page7.answerNo - 10,
            },
        }
    }
    if (page7.answerNo > 3) {
        return {
            page6: {
                verwarming: 'Elektriciteit',
                answerNo: 1,
            },
            page7: {
                ...page7,
                answerNo: page7.answerNo - 4,
            },
        }
    }
    return {
        page6: {
            verwarming: 'Gas',
            answerNo: 0,
        },
        page7: {
            ...page7,
            answerNo: page7.answerNo,
        },
    }
}

export function getPage6And7(pages) {
    const { 6: page6, 7: page7 } = pages

    if (!page7?.fromImprovement) {
        return {
            page6,
            page7,
        }
    }

    return getAnswer6And7(page7)
}

export function getQuantity(response) {
    return {
        gas: response?.data?.current?.energyUseGas,
        electricity: response?.data?.current?.energyUseElec,
    }
}

export const setHeatLossData = createAsyncThunk(
    'result/setHeatLossData',
    async ({userId, pages, buildingData, isFirstTime,}, thunkAPI) => {
        
        
      const buildingType = getBuildingType(pages?.[1]?.answerNo);
      const area = getArea(
        pages?.[3]?.answerNo,
        pages?.[1]?.answerNo,
        pages?.[3]?.oppervlakte_input,
        buildingData && buildingData[0]
      );
      const { page6, page7 } = getPage6And7(pages);
      const heating = getHeating(page6?.answerNo, page7?.answerNo);
      const heatingWarmWater = getHeatingWarmWater(
        pages?.[11]?.answerNo,
        page6?.answerNo,
        page7?.answerNo
      );
      const typeOfHeating = getTypeOfHeating(page6?.answerNo, page7?.answerNo);
  
      const ventilation = getVentilation(pages?.[21]?.answerNo);
      const slopingRoofIsolation = getSlopingRoofIsolation(pages?.[12]?.answerNo);
      const flatRoofIsolation = getFlatRoofIsolation(
        pages?.[13]?.answerNo,
        slopingRoofIsolation
      );
      const roofType = getRoofType(pages?.[13]?.answerNo);
      const florType = getFlorType(pages?.[20]?.answerNo);
  
      const windowType = getWindowType(
        pages?.[15]?.answerNo || pages?.[16]?.answerNo
      );
  
      const isolationGround = getIsolationGround(pages?.[20]?.answerNo);
      const isolationBasement = getIsolationBasement(pages?.[20]?.answerNo);
  
      const wallIsolationAchter = getWallIsolation(pages?.[18]?.answerNo);
      const wallIsolationVoor = getWallIsolation(pages?.[17]?.answerNo);
      const wallIsolation =
        wallIsolationAchter === 'matig' || wallIsolationVoor === 'matig'
          ? 'matig'
          : wallIsolationAchter === 'nietZonderSpouw' ||
            wallIsolationVoor === 'nietZonderSpouw'
          ? 'nietZonderSpouw'
          : 'licht';
  
      const request = {
        bouwjaarVolgensEPC: 2015,
        pvAanwezig: pages?.[22]?.answerNo === 0,
        typeDak: roofType,
        typeGeometrie: area,
        typeGevelIsolatie: wallIsolation,
        typeHellendDakIsolatie: slopingRoofIsolation,
        typePlatDakIsolatie: flatRoofIsolation,
        typeVenster: windowType,
        typeVentilatie: ventilation,
        typeVerwarming: typeOfHeating,
        typeVloer: florType,
        typeVloerBovenKelderIsolatie: isolationBasement,
        typeVloerOpVolleGrondIsolatie: isolationGround,
        typeWoning: buildingType,
        verwarmSanitairWarmWaterMet: heatingWarmWater,
        verwarmingEnergiedrager: heating,
        zonneboilerAanwezig: pages?.[11]?.answerNo === 1,
      };
  
      try {
  
        const response = await fetch(
          `https://energie-dev.api.vlaanderen.be/energie-api/v1/berekenIndicatief`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-apikey': 'gkRYrwOouhFgYzhmt9Ov5JBGkZRqkZhJ',
            },
            body: JSON.stringify(request),
          }
        );
  
        if (!response.ok) {
          throw new Error('Failed to fetch heat loss data');
        }
  
        const data = await response.json();
  
        thunkAPI.dispatch(setStateHeatLossData(data));
  
        if (isFirstTime) {
          thunkAPI.dispatch(setActualEpcLabel(data.label));
        }
  
        sessionPutCall(userId, {
          epc_score: data.primairEnergieverbruikPerM2,
        });
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  );

const resultSlice = createSlice({
    name: 'questionnaire',
    initialState: {
        investeer: {},
        area: {},
        energielening: 0,
        premies: {},
        minderEnergie: {},
        electricity: {},
        gas: {},
        verkoopElectricity: {},
        co2: {},
        bespaar: {},
        bespaarCo2: 0,
        heatLossData: {},
        actualEpcLabel:""
    },
    reducers: {
        setEnergielening(state, action) {
            state.energielening = action.payload
        },
        setPremies(state, action) {
            state.premies = {
                ...state.premies,
                ...action.payload,
            }
        },
        setMinderEnergie(state, action) {
            state.minderEnergie = {
                ...state.minderEnergie,
                ...action.payload,
            }
        },
        setVerkoopElectricity(state, action) {
            state.verkoopElectricity = {
                ...state.verkoopElectricity,
                ...action.payload,
            }
        },
        setElectricity(state, action) {
            state.electricity = {
                ...state.electricity,
                ...action.payload,
            }
        },
        setGas(state, action) {
            state.gas = {
                ...state.gas,
                ...action.payload,
            }
        },
        setCO2(state, action) {
            state.co2 = {
                ...state.co2,
                ...action.payload,
            }
        },
        setBespaar(state, action) {
            state.bespaar = {
                ...state.bespaar,
                ...action.payload,
            }
        },
        setArea(state, action) {
            state.area = {
                ...state.area,
                ...action.payload,
            }
        },
        setBespaarCo2(state, action) {
            state.bespaarCo2 = action.payload
        },
        setStateHeatLossData(state, action) {
            state.heatLossData = action.payload
        },
        setActualEpcLabel(state, action) {
            state.actualEpcLabel = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setInvesteer.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        })
    },
})

export const { 
    setEnergielening,
    setPremies,
    setMinderEnergie,
    setVerkoopElectricity,
    setElectricity,
    setGas,
    setCO2,
    setBespaar,
    setArea,
    setBespaarCo2, 
    setStateHeatLossData, 
    setActualEpcLabel 
} = resultSlice.actions
export default resultSlice.reducer
